import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalRedirect = makeShortcode("ExternalRedirect");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ExternalRedirect href="https://docs.starswap.xyz/protocol/V2/concepts/advanced-topics/research" mdxType="ExternalRedirect" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      